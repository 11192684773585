<template>
  <div>
    <v-card outlined>
      <v-toolbar dense dark flat color="primary">
        <v-toolbar-title>
          <span>Calificaciones</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div v-if="alumnos.length && resultados.length">
          <tabla-alumnos
            :alumnos="alumnos"
            :calificaciones="resultados"
          ></tabla-alumnos>
        </div>
        <div v-else>
          <span>Sin resultados para mostrar.</span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { obtenerResultadosAplicacion } from "./examenAplicacion.service";
import { obtenerIntegrantesCursoService } from "../integrantes/integrantes.service";

export default {
  props: {
    idCurso: { type: String, required: true },
    tipoCurso: { type: String, required: true },
    idModulo: { type: String, required: true },
    idAplicacion: { type: String, required: true },
  },

  components: {
    "tabla-alumnos": () => import("./calificacionAlumnosAplicacion.vue"),
  },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
  },

  data: () => ({
    loading: false,
    alumnos: [],
    resultados: [],
  }),

  mounted() {
    this.getResultados();
    this.getAlumnos();
  },

  methods: {
    async getResultados() {
      this.loading = true;

      try {
        const serverResponse = await obtenerResultadosAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          idAplicacion: this.idAplicacion,
          tipoCurso: this.tipoCurso,
        });
        this.loading = false;

        if (serverResponse.ok)
          this.resultados = serverResponse.resultados.map((e) => ({
            ...e,
            calificacion: e.calificacion ? e.calificacion.toFixed(2) : 0,
          }));
        else this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async getAlumnos() {
      this.loading = true;

      try {
        const serverResponse = await obtenerIntegrantesCursoService(
          this.idCurso
        );
        this.loading = false;

        if (serverResponse.ok)
          this.alumnos = serverResponse.alumnos.map((alumno) => ({
            ...alumno,
            _id: alumno.idUsuario,
          }));
        else this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
